.customOptions {
    .leaflet-popup-content-wrapper {
        @apply rounded-none min-w-64 min-h-60;

        .leaflet-popup-content {
            @apply w-full h-full;
        }
    }

    .leaflet-popup-tip-container {
        display: none;
    }

    a.leaflet-popup-close-button {
        @apply top-6 right-4 text-primary #{!important};
    }
}
