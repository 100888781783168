@config "./tailwind.config.ts";

@font-face {
    font-family: 'Nunito Sans';
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
    src: url('./fonts/nunito-sans/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf');
    font-variation-settings:
        'wdth' 100,
        'YTLC' 500;
}

@font-face {
    font-family: 'Nunito Sans';
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    src: url('./fonts/nunito-sans/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf');
    font-variation-settings:
        'wdth' 100,
        'YTLC' 500;
}

@font-face {
    font-family: 'Nunito Sans';
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    src: url('./fonts/nunito-sans/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf');
    font-variation-settings:
        'wdth' 100,
        'YTLC' 500;
}


@font-face {
    font-family: 'Nunito Sans';
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    src: url('./fonts/nunito-sans/NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf');
    font-variation-settings:
        'wdth' 100,
        'YTLC' 500;
}
